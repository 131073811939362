<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>团体赛赛务管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 团体赛赛程 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">团体赛赛程</span>
        </el-col>
      </el-row>
      <div class="schedule-list">
        <div
          class="schedule-item"
          v-for="(itemData, index) in scheduleCardList"
          v-bind:key="index"
        >
          <gameStageBackview :itemData="itemData" :key="index" :itemIndex="index" @itemClicked="scheduleCardTap"></gameStageBackview>
        </div>
      </div>
    </el-card>
    <!-- 团体赛赛务工具 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">团体赛赛务工具</span>
        </el-col>
      </el-row>
      <el-row :gutter="30" style="margin-top: 34px;">
        <el-col :span="4" v-for="(item, i) of racingToolList" v-bind:key="i">
          <gameToolBackView :itemData="item" :key="i" @itemClicked="racingToolClicked"></gameToolBackView>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="16">
      <el-col :span="12">
        <!-- 待处理事务 -->
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">待处理事务</span>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :span="6" v-for="(item, i) of processedList" v-bind:key="i">
              <gameTransactionBackview :itemData="item" :key="i" @itemClicked="handleToPage"></gameTransactionBackview>
              <!-- <div class="processed-list-con">
                <div class="processed-image" :class="'coloricon'+(i+1)"><i class="icon iconfont" :class="item.icon"></i></div>
                <div>
                  <div class="processed-desc">{{ item.number }}</div>
                  <div class="processed-title">{{ item.title }}</div>
                </div>
              </div> -->
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card style="margin-top: 15px">
          <el-row class="title_row">
            <el-col>
              <span class="title_class">数据统计</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6" v-for="(item, i) of dataStatisticsLost" v-bind:key="i">
              <div class="processed-list-back" @click="racingToolClicked(item.menuTag)">
                <div class="dataStatistics-desc">{{ item.rightDesc }}</div>
                <div class="dataStatistics-title">{{ item.rightTitle }}</div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 数据统计 -->
    <el-card style="margin-top: 15px">
      <el-row style="margin: 0 auto 30px; font-size: 16px">
        <el-col>
          <span class="title_class">实时报名情况</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div class="item-sign-backview">
            <div class="item-sign-data">
              <div
                class="item-sign-data-child"
                v-for="(item, index) in signInListData"
                v-bind:key="index"
              >
                <div class="child-data-title">{{ item.titleStr }}</div>
                <div class="child-data-number">{{ item.descStr }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="item-sign-backview"></div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      scheduleCardList: [
        {
          scheduleName: '全国半决选',
          scheduleId: '17',
          promotionNum: 0,
          descStr: '参加全国半决选战队',
          backGround: '#fb6260',
          flag: this.$chnEngStatusCode.NATIONAL_FINALS
        }, {
          scheduleName: '全国总决赛',
          scheduleId: '18',
          promotionNum: 0,
          descStr: '参加全国总决选战队',
          backGround: '#8167f5',
          flag: this.$chnEngStatusCode.NATIONAL_FINALS
        }
      ],
      racingToolList: [
        {
          icon: 'icon-huiyuanjulebu',
          rightTitle: '报名信息管理',
          rightDesc: '俱乐部报名信息管理',
          menuTag: '1',
          flag: this.$chnEngStatusCode.examination_club_management,
          turnUrl: '/raceManagement/team_management/team_club_management'
        },
        {
          icon: 'icon-tuandui',
          rightTitle: '俱乐部管理',
          rightDesc: '俱乐部、战队管理',
          flag: this.$chnEngStatusCode.examination_team_management,
          menuTag: '2',
          turnUrl: '/raceManagement/team_management/team_team_management'
        }
        // ,
        // {
        //   icon: 'icon-rili',
        //   rightTitle: '比赛时间管理',
        //   rightDesc: '修改赛区/学校比赛时间',
        //   menuTag: '3',
        //   flag: ''
        // },
        // {
        //   icon: 'icon-tongzhi',
        //   rightTitle: '比赛通知管理',
        //   rightDesc: '新增/修改比赛通知',
        //   menuTag: '4',
        //   flag: ''
        // },
        // {
        //   icon: 'icon-house-full',
        //   rightTitle: '区域赛考场管理',
        //   rightDesc: '新增/修改考场信息',
        //   menuTag: '5',
        //   flag: ''
        // }
      ],
      processedList: [
        {
          icon: 'icon-user',
          title: '待缴费选手',
          number: 0,
          menuTag: '1',
          flag: ''
        },
        {
          icon: 'icon-tuandui',
          title: '待缴费战队',
          number: 0,
          menuTag: '2',
          flag: ''
        },
        {
          icon: 'icon-fahuo2',
          title: '待发货订单',
          number: 0,
          menuTag: '3',
          flag: ''
        },
        {
          icon: 'icon-tuikuanguanli',
          title: '待处理退款申请',
          number: 0,
          menuTag: '4',
          flag: ''
        }
      ],
      dataStatisticsLost: [
        {
          leftImg: require('@/assets/img/icon-shijuanguanli.png'),
          rightTitle: '已注册战队',
          rightDesc: 0,
          menuTag: '1'
        },
        {
          leftImg: require('@/assets/img/icon-youhuiquan.png'),
          rightTitle: '已缴费战队',
          rightDesc: 0,
          menuTag: '2'
        },
        {
          leftImg: require('@/assets/img/icon-rili.png'),
          rightTitle: '已参与学校',
          rightDesc: 0,
          menuTag: '3'
        },
        {
          leftImg: require('@/assets/img/icon-school.png'),
          rightTitle: '已缴费选手',
          rightDesc: 0,
          menuTag: '4'
        }
      ],
      signInListData: [
        {
          titleStr: '今日缴费总额',
          descStr: 0
        },
        {
          titleStr: '销售目标完成率',
          descStr: '0%'
        },
        {
          titleStr: '报名剩余时间',
          descStr: '00:00:00'
        }
      ]
    }
  },
  methods: {
    handleToPage () {

    },
    racingToolClicked (itemData) {
      if (itemData.turnUrl) {
        this.$router.push({
          path: itemData.turnUrl
        })
      }
    },
    scheduleCardTap (scheduleData) {
      console.log('scheduleData', scheduleData)
      this.$router.push(
        {
          path: '/team_management/team_manage_homeview/team_sign_management',
          query: {
            raceSchedule: scheduleData.scheduleId
          }
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.schedule_card {
  width: 100%;
  height: 54px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.schedule_gameName {
  text-align: left;
  color: white;
  font-size: 20px;
  margin-bottom: 6px;
}

.schedule_gameNumber {
  text-align: left;
  color: white;
  font-size: 12px;
  margin-left: 20px;
}
.item-sign-backview {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.item-sign-data {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.item-sign-data-child {
  width: 33%;
  display: flex;
  flex-direction: column;
}

</style>
